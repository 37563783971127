import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Elements';

export const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleBack = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center">
        <p className="error-code">404</p>
        <h1>{t('PAGE.404.TITLE')}</h1>
        <h4>{t('PAGE.404.SUB_TITLE')}</h4>
        <Button onClick={handleBack}>{t('COMMON.BUTTON.BACK')}</Button>
      </div>
    </div>
  );
};
