import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useRoomType } from '../../features/hotelManagement/api/getRoomTypeDetail';

const Breadcrumb = () => {
  const { data, mutateAsync } = useRoomType();
  const location = useLocation();
  const [pathnames, setPathnames] = useState<string[]>(
    location.pathname.split('/').filter((x) => x)
  );
  useEffect(() => {
    if (location.pathname.includes('/roomType/')) {
      const paths = location.pathname.split('/').filter((x) => x);
      mutateAsync({ id: Number(paths[1] || null) });
    } else {
      setPathnames(location.pathname.split('/').filter((x) => x));
    }
    // eslint-disable-next-line
  }, [location]);
  useEffect(() => {
    if (data) {
      const { property_id, id } = data;
      setPathnames(['hotel', `${property_id}`, 'roomType', `${id}`]);
    }
    // eslint-disable-next-line
  }, [data]);
  if (location.pathname === '/') {
    return <></>;
  }
  return (
    <div style={{ display: 'flex' }} className="bg-[#F4F5F6]">
      <nav
        className="flex text-gray-700 border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 w-full p-4"
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          {location.pathname === '/' ? null : (
            <li className="inline-flex items-center">
              <Link
                to="/hotel"
                className="text-bold text-[#A60000] hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  className="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                </svg>
                Home
              </Link>
            </li>
          )}
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            return (
              <div key={index}>
                {last ? (
                  <li aria-current="page">
                    <Link className="flex items-center text-bold text-[#A60000]" to={to}>
                      <svg
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="text-gray-400 ml-1 md:ml-2 text-sm font-medium dark:text-gray-500">
                        {value}
                      </span>
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link className="flex items-center text-bold text-[#A60000]" to={to}>
                      <svg
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <a
                        href="#"
                        className="text-bold text-[#A60000] hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                      >
                        {value}
                      </a>
                    </Link>
                  </li>
                )}
              </div>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
