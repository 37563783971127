import * as React from 'react';

import { Head } from '../Head';

type ContentLayoutProps = {
  children: React.ReactNode;
  action?: React.ReactNode;
  submitAction?: React.ReactNode;
  title: string;
};

export const ContentLayout = ({ children, title, action, submitAction }: ContentLayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="p-4 flex flex-col">
        <div className="bg-white rounded-md mb-2 p-4 flex justify-between">
          <div className="text-lg font-semibold text-red-100">{title}</div>
          <div>{action}</div>
        </div>
        <div
          className="relative overflow-y-auto rounded-md"
          style={{ height: submitAction ? `calc(100vh - 232px - 50px)` : `calc(100vh - 232px)` }}
        >
          {children}
        </div>
        {submitAction}
      </div>
    </>
  );
};
