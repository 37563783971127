import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { NotFound } from '@/features/misc';
import { lazyImport } from '@/utils/lazyImport';

// const { DiscussionsRoutes } = lazyImport(
//   () => import('@/features/discussions'),
//   'DiscussionsRoutes'
// );
const { HotelManagementRoutes } = lazyImport(
  () => import('@/features/hotelManagement'),
  'HotelManagementRoutes'
);
const { PrefecturesManagementRoutes } = lazyImport(
  () => import('@/features/PrefecturesManagerment'),
  'PrefecturesManagementRoutes'
);

const { UserManagementRoutes } = lazyImport(
  () => import('@/features/users'),
  'UserManagementRoutes'
);

const { BookingManagementRoutes } = lazyImport(
  () => import('@/features/bookingManagement'),
  'BookingManagementRoutes'
);

const { Dashboard } = lazyImport(() => import('@/features/misc'), 'Dashboard');
const { Profile } = lazyImport(() => import('@/features/profile'), 'Profile');
// const { Users } = lazyImport(() => import('@/features/users'), 'Users');
const { ConfigureManagementRoutes } = lazyImport(
  () => import('@/features/configure'),
  'ConfigureManagementRoutes'
);

const { HomepageManagementRoutes } = lazyImport(
  () => import('@/features/homepageManagement'),
  'HomepageManagementRoutes'
);

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/prefectures/*', element: <PrefecturesManagementRoutes /> },
      { path: '/*', element: <HotelManagementRoutes /> },
      { path: '/user/*', element: <UserManagementRoutes /> },
      { path: '/booking/*', element: <BookingManagementRoutes /> },
      { path: '/config/*', element: <ConfigureManagementRoutes /> },
      { path: '/homepage/*', element: <HomepageManagementRoutes /> },
      // { path: '/users', element: <Users /> },
      { path: '/profile', element: <Profile /> },
      { path: '/', element: <Dashboard /> },
      { path: '*', element: <NotFound /> },
    ],
  },
];
