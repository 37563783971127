import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { Head } from '@/components/Head';
import { useAuth } from '@/lib/auth';

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  useEffect(() => {
    if (user) {
      navigate('/hotel');
    } else {
      navigate('/auth/login');
    }
    // eslint-disable-next-line
  }, [user]);
  return (
    <>
      <Head description="Welcome to Travel With Dog" />
    </>
  );
};
