import * as React from 'react';

type ContentLayoutTabsProps = {
  children: React.ReactNode;
  action?: React.ReactNode;
  submitAction?: React.ReactNode;
  title: React.ReactNode;
};

export const ContentLayoutTabs = ({
  children,
  title,
  action,
  submitAction,
}: ContentLayoutTabsProps) => {
  return (
    <>
      <div className="p-4 flex flex-col">
        <div className="bg-white rounded-md mb-2 p-4 flex justify-between relative">
          <div className="text-lg font-semibold text-red-100 flex-1">{title}</div>
          <div className="absolute right-4">{action}</div>
        </div>
        <div
          className="relative overflow-y-auto rounded-md"
          style={{ height: submitAction ? `calc(100vh - 262px - 50px)` : `calc(100vh - 262px)` }}
        >
          {children}
        </div>
        {submitAction}
      </div>
    </>
  );
};
