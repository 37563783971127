import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
  target?: boolean;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { login, isLoggingIn, refetchUser } = useAuth();
  const { t } = useTranslation();
  const schema = z.object({
    email: z.string().min(1, t('COMMON.REQUIRED')),
    password: z.string().min(1, t('COMMON.REQUIRED')),
  });
  return (
    <div>
      <Form<LoginValues, typeof schema>
        onSubmit={async (values) => {
          await login(values);
          await refetchUser();
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label={t('COMMON.EMAIL')}
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label={t('COMMON.PASSWORD')}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button isLoading={isLoggingIn} variant="base" type="submit" className="w-full">
                {t('COMMON.SIGN_IN')}
              </Button>
            </div>
          </>
        )}
      </Form>
      {/* <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          <span>
            {t('COMMON.ACCOUNT.NOT.HAVE')}
            <span className="pl-2">
              <Link
                to="/auth/register"
                target={target ? '_blank' : ''}
                className="font-medium text-red-100 hover:text-red-100"
              >
                {t('COMMON.SIGN_UP')}
              </Link>
            </span>
          </span>
        </div>
      </div> */}
    </div>
  );
};
