import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { QueryConfig, queryClient } from '@/lib/react-query';
import { RoomType } from '@/types';
type Payload = {
  id: number;
};

export const search = ({ id }: Payload): Promise<RoomType> => {
  return axios.get(`/room_type/${id}`);
};

type QueryFnType = typeof search;

type UseRoomTypeOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useRoomType = ({ config }: UseRoomTypeOptions = {}) => {
  return useMutation({
    onMutate: async (newRoomType) => {
      return { newRoomType };
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['room-type-detail'], data);
    },
    ...config,
    mutationFn: search,
  });
};
