import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';

export const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  useEffect(() => {
    if (user) {
      navigate('/hotel');
    } else {
      navigate('/auth/login');
    }
    // eslint-disable-next-line
  }, [user]);
  return (
    <ContentLayout title="Dashboard">
      <Spinner size="lg" />
    </ContentLayout>
  );
};
