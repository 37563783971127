import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password';
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (props: any) => void;
  maxLength?: number;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type = 'text',
    label,
    className,
    registration,
    error,
    placeholder,
    disabled,
    value,
    maxLength,
    onChange,
  } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <input
        type={type}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        className={clsx(
          'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
          className
        )}
        {...registration}
      />
    </FieldWrapper>
  );
};
