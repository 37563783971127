import { Dialog, Menu, Transition } from '@headlessui/react';
import { UserIcon, MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';

// import { ReactComponent as Bed } from '@/assets/Bed.svg';
import { ReactComponent as Buildings } from '@/assets/Buildings.svg';
import { ReactComponent as CalendarMark } from '@/assets/CalendarMark.svg';
import { ReactComponent as Home } from '@/assets/home.svg';
import { ReactComponent as location } from '@/assets/location.svg';
import logo from '@/assets/logo.svg';
import { ReactComponent as Star } from '@/assets/Star.svg';
import { ReactComponent as UserCircle } from '@/assets/UserCircle.svg';
import { useAuth } from '@/lib/auth';

import Breadcrumb from './BreadCrumb';
// import { useAuthorization, ROLES } from '@/lib/authorization';

type SideNavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

const SideNavigation = () => {
  const { t } = useTranslation();
  // const { checkAccess } = useAuthorization();
  const navigation_hotel = [
    { name: t('COMMON.DASHBOARD.HOTEL'), to: './hotel', icon: Buildings },
    // { name: t('COMMON.DASHBOARD.HOTEL_ROOM'), to: './room', icon: Bed },
    // checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
    //   name: 'Users',
    //   to: './users',
    //   icon: UsersIcon,
    // },
  ].filter(Boolean) as SideNavigationItem[];

  const navigation_general_config = [
    { name: t('COMMON.DASHBOARD.CONFIGURE'), to: './config/property_facility', icon: Star },
    {
      name: t('COMMON.DASHBOARD.HOMEPAGE_CONFIG'),
      to: './homepage/contacts',
      icon: Home,
    },
    { name: t('COMMON.DASHBOARD.PREFECTURES'), to: './prefectures', icon: location },
  ].filter(Boolean) as SideNavigationItem[];

  const navigation_member = [
    { name: t('COMMON.DASHBOARD.MEMBERS'), to: './user', icon: UserCircle },
    { name: t('COMMON.DASHBOARD.RESERVATION'), to: './booking/', icon: CalendarMark },
  ].filter(Boolean) as SideNavigationItem[];
  return (
    <>
      <div className="font-bold text-sm text-gray-102 p-2">ホテル経営</div>
      {navigation_hotel.map((item, index) => (
        <NavLink
          end={index === 0}
          key={item.name}
          to={item.to}
          className={clsx(
            'text-gray-101 hover:bg-red-100 hover:text-white',
            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
          )}
          activeClassName="bg-red-100 text-white"
        >
          <div className="p-1 bg-white rounded-md mr-4">
            <item.icon
              className={clsx('text-gray-400 group-hover:text-gray-300', 'flex-shrink-0 h-7 w-7')}
              aria-hidden="true"
            />
          </div>
          {item.name}
        </NavLink>
      ))}
      <div className="font-bold text-sm text-gray-102 p-2">一般的な設定</div>
      {navigation_general_config.map((item, index) => (
        <NavLink
          end={index === 0}
          key={item.name}
          to={item.to}
          className={clsx(
            'text-gray-101 hover:bg-red-100 hover:text-white',
            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
          )}
          activeClassName="bg-red-100 text-white"
        >
          <div className="p-1 bg-white rounded-md mr-4">
            <item.icon
              className={clsx('text-gray-400 group-hover:text-gray-300', 'flex-shrink-0 h-7 w-7')}
              aria-hidden="true"
            />
          </div>
          {item.name}
        </NavLink>
      ))}
      <div className="font-bold text-sm text-gray-102 p-2">{t('COMMON.DASHBOARD.MEMBER')}</div>
      {navigation_member.map((item, index) => (
        <NavLink
          end={index === 0}
          key={item.name}
          to={item.to}
          className={clsx(
            'text-gray-101 hover:bg-red-100 hover:text-white',
            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
          )}
          activeClassName="bg-red-100 text-white"
        >
          <div className="p-1 bg-white rounded-md mr-4">
            <item.icon
              className={clsx('text-gray-400 group-hover:text-gray-300', 'flex-shrink-0 h-7 w-7')}
              aria-hidden="true"
            />
          </div>
          {item.name}
        </NavLink>
      ))}
    </>
  );
};

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const UserNavigation = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();

  const userNavigation = [
    { name: t('COMMON.PROFILE'), to: '../profile' },
    {
      name: t('COMMON.SIGN_OUT'),
      to: '',
      onClick: () => {
        logout();
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  return (
    <Menu as="div" className="ml-3 relative bg-white rounded-lg">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="max-w-xs  bg-red-100 p-2 flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-2">
              <span className="sr-only">Open user menu</span>
              <UserIcon className="h-4 w-4 rounded-full" />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

type MobileSidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileSidebar = ({ sidebarOpen, setSidebarOpen }: MobileSidebarProps) => {
  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 md:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-4">
              <Logo />
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                <SideNavigation />
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  );
};

const Sidebar = () => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1 bg-red-100">
          <div className="flex items-center h-14 flex-shrink-0 mx-2 mt-2 bg-white border-b p-2 rounded-t-lg shadow-lg">
            <Logo />
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto px-2 bg-[#F4F5F6]">
            <nav className="flex-1 px-2 py-4  bg-white space-y-3 shadow-lg">
              <SideNavigation />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

const Logo = () => {
  return (
    <Link className="flex items-center text-white" to="/">
      <img className="h-12 w-auto" src={logo} alt="Workflow" />
      {/* <span className="text-xl text-white font-semibold">Travel With Dog</span> */}
    </Link>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-end bg-red-100">
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <Breadcrumb />
          {children}
        </main>
      </div>
    </div>
  );
};
