import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Sns } from '../Types';

export const getSns = (): Promise<Sns[]> => {
  return axios.get('/homepage/sns');
};

type QueryFnType = typeof getSns;

type UseSnsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useSns = ({ config }: UseSnsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['Sns'],
    queryFn: () => getSns(),
  });
};
